import { AuthProviders } from '@streamloots/streamloots-types';
import { ExternalCreatorLink } from './ExternalCreatorLink';

interface StreamLinkProps {
  primaryAuthProvider: AuthProviders;
  streamingId?: string;
}

export const StreamLink = ({ primaryAuthProvider, streamingId }: StreamLinkProps): JSX.Element | null => {
  if (!streamingId) {
    return null;
  }

  switch (primaryAuthProvider) {
    case AuthProviders.TWITCH:
      return (
        <ExternalCreatorLink
          icon="twitch"
          eventId="twitch"
          title="Twitch channel"
          url={`https://www.twitch.tv/${streamingId}`}
        />
      );
    case AuthProviders.KICK:
      return (
        <ExternalCreatorLink icon="kick" eventId="kick" title="Kick channel" url={`https://kick.com/${streamingId}`} />
      );
    case AuthProviders.TROVO:
      return (
        <ExternalCreatorLink
          icon="trovo"
          eventId="trovo"
          title="Trovo channel"
          url={`https://trovo.live/${streamingId}`}
        />
      );
    case AuthProviders.GOOGLE:
      return (
        <ExternalCreatorLink
          icon="youtube"
          eventId="youtube"
          title="YouTube channel"
          url={`https://www.youtube.com/channel/${streamingId}`}
        />
      );

    default:
      return null;
  }
};
